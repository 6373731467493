import { api, EnerbitPagination, getMagicalParamUrl } from "@enerbit/base";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isThirdPartyService } from "../../helpers/thirdPartyServices";
import {
  ServiceAccountState,
  ServicesAccount,
  ServicesCards,
} from "../../models/serviceAccountState";
import { StateStorage } from "../../models/stateStorage";

export interface GetServiceAccountsRelationships {
  page: number;
  size: number;
}
export const getServiceAccountsRelationships = createAsyncThunk(
  "serviceAccounts/getServiceAccountsRelationships",
  async ({ page, size }: GetServiceAccountsRelationships) => {
    let param = getMagicalParamUrl();
    const res = await api.get("/accounts/service-accounts-relationships/", {
      params: {
        page: page,
        size: size,
        user_id: param,
      },
    });
    return res.data;
  }
);

export const getCardsServiceAccount = createAsyncThunk(
  "serviceAccount/cardsServiceAccount",
  async (service: string, thunkAPI) => {
    const state = thunkAPI.getState() as StateStorage;
    if (state.serviceUser.searchUser) {
      const res = await api.get(
        `/accounts/service-relationships/?service_account_id=${service}&user_id=${state.serviceUser.searchUser.id}`
      );
      return res.data;
    }
  }
);

const initialState: ServiceAccountState = {
  hasError: false,
  isLoading: false,
  isLoadingCards: false,
  servicesAccountData: {
    items: [],
    page: 0,
    pages: 0,
    size: 0,
    total: 0,
    next_page: 0,
  },
  serviceAccountSelect: "",
  servicesAccountDataFilter: [],
  servicesAccountCards: [],
  tabSelectedAccessManagement: 0,
};

export const serviceAccountSlice = createSlice({
  name: "serviceAccount",
  initialState,
  reducers: {
    setDefaultServiceAccount: (state) => {
      state.servicesAccountCards = [];
    },
    setTabSelectedAccessManagement: (state, { payload }) => {
      state.tabSelectedAccessManagement = payload;
    },
    setDefaultServicesAccountData: (state) => {
      state.servicesAccountData = {
        items: [],
        page: 0,
        pages: 0,
        size: 0,
        total: 0,
        next_page: 0,
      };
    },
    setServicesAccountDataFilter: (state, { payload }) => {
      state.servicesAccountDataFilter = payload;
    },
    setServicesAccountSelect: (state, { payload }) => {
      state.serviceAccountSelect = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardsServiceAccount.pending.type, (state) => {
        state.isLoadingCards = true;
      })
      .addCase(
        getCardsServiceAccount.fulfilled.type,
        (state, action: PayloadAction<Array<ServicesCards>>) => {
          const filteredCards = action.payload.filter(
            (e) =>
              e.service_type_name === "electricity-supply" ||
              isThirdPartyService(e.service_type_name)
          );
          if (filteredCards.length > 0) {
            state.servicesAccountCards = filteredCards;
          }
          state.isLoadingCards = false;
        }
      )
      .addCase(getCardsServiceAccount.rejected.type, (state) => {
        state.isLoadingCards = false;
      })
      .addCase(getServiceAccountsRelationships.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getServiceAccountsRelationships.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<ServicesAccount>>) => {
          const serviceAccounts = [
            ...state.servicesAccountData.items,
            ...action.payload.items,
          ];
          const uniqueServiceAccounts = serviceAccounts.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          state.servicesAccountData = {
            ...action.payload,
            items: uniqueServiceAccounts,
          };
          state.isLoading = false;
        }
      )
      .addCase(getServiceAccountsRelationships.rejected.type, (state) => {
        state.isLoading = false;
        state.hasError = true;
      });
  },
});

export const {
  setDefaultServiceAccount,
  setDefaultServicesAccountData,
  setServicesAccountDataFilter,
  setServicesAccountSelect,
  setTabSelectedAccessManagement,
} = serviceAccountSlice.actions;

export const hasError = (state: StateStorage) => state.serviceAccount.hasError;
export const isLoading = (state: StateStorage) =>
  state.serviceAccount.isLoading;
export const isLoadingCards = (state: StateStorage) =>
  state.serviceAccount.isLoadingCards;
export const servicesAccountData = (state: StateStorage) =>
  state.serviceAccount.servicesAccountData;
export const servicesAccountCards = (state: StateStorage) =>
  state.serviceAccount.servicesAccountCards;
export const servicesAccountDataFilter = (state: StateStorage) =>
  state.serviceAccount.servicesAccountDataFilter;
export const serviceAccountSelect = (state: StateStorage) =>
  state.serviceAccount.serviceAccountSelect;
export const tabSelectedAccessManagement = (state: StateStorage) =>
  state.serviceAccount.tabSelectedAccessManagement;

export default serviceAccountSlice.reducer;
